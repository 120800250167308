$(function() {
   var player_color = "w";
   window.level = 1;
   $(".play").on("click", function (e) {
      $('.start-btn').hide();
      $("#gameModal").modal('show');
      $(".nav-close.active p").click();
      window.level = $(this).data("it");
	});

   $(".choose-color label").on("click", function (e) {
      $('.task-help').html('');
      player_color = $(this).data("color");
      $('.start-btn').hide();
      $("#gameModal").modal('hide');
      $(".nav-close.active p").click();
      puzzleEditorViewModel.timeouts = [];
      puzzleEditorViewModel.board.onreset();
      puzzleEditorViewModel.board.pturn(false); 
      puzzleEditorViewModel.board.pmturn(false); 
      puzzleEditorViewModel.board.pcturn(false); 
      puzzleEditorViewModel.board.updateBoard();
      puzzleEditorViewModel.board.palings.removeAll();
      puzzleEditorViewModel.board.movelast.removeAll();

      puzzleEditorViewModel.board.disabled(false); 
      window.puzzle = {category: 0, player_color: player_color, flip: player_color == "w" ? false : true, pgn: '[FEN "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1"]\n\n*', number: 0, description: "Game vs bot", it: 0};
      window.puzzle.solution = '';
      puzzleEditorViewModel.load(window.puzzle); 
      puzzleEditorViewModel.board.flip(player_color === "b");
      $('#taskModal2 .description-1').show();
      $('#taskModal2 .description').hide();
      $('#cloud-001 .desc').hide();

      if (player_color === 'b') {
         $('#board').addClass('bot'); 
         $('#board').removeClass('my'); 
         $('.task-help').html('You play black.');
         //var moves_p = puzzleEditorViewModel.board.chess.moves();
         var moves_p = ["e4", "d4", "Nf3", "c4", "g3", "b3", "f4", "Nc3", "b4", "a3", "e3", "d3", "g4", "c3", "h4", "h3", "a4", "Nh3", "f3", "Na3"];
         var moves_l = window.level < 4 ? 20 : 8;
         //var premier_num = Math.floor(Math.random(moves_p.length) * moves_p.length);
         var premier_num = Math.floor(Math.random() * moves_l);
         //window.setTimeout(premierMome(moves_p[premier_num]), 1500);
         window.setTimeout(premierMome(moves_p[premier_num]), 1500);
         //var moveObject = puzzleEditorViewModel.board.makeMove(moves_p[premier_num], true);
         //window.setTimeout(makeComputerMove, 250); //Computer makes the first move
       }else{
         $('#board').addClass('my'); 
         $('#board').removeClass('bot');
         $('.task-help').html('You play white.');
       }
       function premierMome (move){
         var moveObject1 = puzzleEditorViewModel.board.makeMove(move, true);
         $('#board').addClass('my'); 
         $('#board').removeClass('bot'); 
       }

	});
   $(".play-1").on("click", function (e) {
      $('.start-btn').hide();
      $("#gameModal").modal('hide');
      $(".nav-close.active p").click();
	});
   /*
   $(".cancel").on("click", function (e) {
      $('.start-btn').show();
      $("#gameModal").modal('hide');
	});
   
   $('.choose-color').change(function(){
      console.log($("input[name='choose-color']:checked").val());
		player_color = $("input[name='choose-color']:checked").val();
	});
   */
/*

*/
});




(function() {
var getBestMove = (async function (fen, depth) {
   $.ajax({
      url: "/puzzles/best_move",
      type: 'GET', 
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      data: ({fen: fen, depth: depth, host: window.location.hostname}), 
      dataType: "json",
      success: function(data) {
         const bestMove = data.bestmove;
         return bestMove;
      }                  
    });

   /*
   try {
     const response = await fetch(`https://stockfish.online/api/s/v2.php?fen=${fen}&depth=${depth}`);
     const data = await response.json();
     if (data.success) {
       const bestMove = data.bestmove.split(' ')[1]; //Extract the move part
       console.log('Best move:', JSON.stringify(data));
       console.log('Best move:', bestMove);
       return bestMove;
     } else {
       console.error('Error getting best move:', data);
       return null;
     }
   } catch (error) {
     console.error('Error fetching best move:', error);
     return null;
   }
   */
});
window.getBestMove = getBestMove;
}).call(this);
(function() {
   var makeComputerMove = (async function() {

      //=======================ii================================
      var minimaxRoot =function(depth, isMaximisingPlayer) {
         //console.log("minimaxRoot ", depth, isMaximisingPlayer)
      	if(isMaximisingPlayer){
	      	var newGameMoves = puzzleEditorViewModel.board.chess.moves();
            //console.log(JSON.stringify(newGameMoves));
	      	var bestMove = -9999;
	      	var bestMoveFound;

   		   for(var i = 0; i < newGameMoves.length; i++) {
   		   	var newGameMove = newGameMoves[i]
   			   puzzleEditorViewModel.board.chess.move(newGameMove);
   	   		var value = minimax(depth - 1, -10000, 10000, !isMaximisingPlayer);
   	   		puzzleEditorViewModel.board.chess.undo();
   	   		if(value >= bestMove) {
   	   			bestMove = value;
   	   			bestMoveFound = newGameMove;
   	   		}
   	   	}
   	   }else{
   	   	var newGameMoves = puzzleEditorViewModel.board.chess.moves();
   	   	var bestMove = 9999;
   	   	var bestMoveFound;

   	   	for(var i = 0; i < newGameMoves.length; i++) {
   	   		var newGameMove = newGameMoves[i]
   	   		puzzleEditorViewModel.board.chess.move(newGameMove);
   	   		var value = minimax(depth - 1, -10000, 10000, !isMaximisingPlayer);
   	   		puzzleEditorViewModel.board.chess.undo();
   	   		if(value <= bestMove) {
   	   			bestMove = value;
   	   			bestMoveFound = newGameMove;
   	   		}
   	   	}
   	   }
         //console.log(bestMoveFound)
   	   return bestMoveFound;
      };

      var minimax = function (depth, alpha, beta, isMaximisingPlayer) {
      	//positionCount++;
      	if (depth === 0) {
      		return -evaluateBoard(puzzleEditorViewModel.board.chess.board());
      	}

	      var newGameMoves = puzzleEditorViewModel.board.chess.moves();

      	if (isMaximisingPlayer) {
	      	var bestMove = -9999;
	      	for (var i = 0; i < newGameMoves.length; i++) {
	      		puzzleEditorViewModel.board.chess.move(newGameMoves[i]);
		      	bestMove = Math.max(bestMove, minimax(depth - 1, alpha, beta, !isMaximisingPlayer));
		      	puzzleEditorViewModel.board.chess.undo();
		      	alpha = Math.max(alpha, bestMove);
		      	if (beta <= alpha) {
		      		return bestMove;
			      }
	      	}
            //console.log("00  ", bestMove);
		      return bestMove;
      	} 
	      else {
		      var bestMove = 9999;
		      for (var i = 0; i < newGameMoves.length; i++) {
			      puzzleEditorViewModel.board.chess.move(newGameMoves[i]);
			      bestMove = Math.min(bestMove, minimax(depth - 1, alpha, beta, !isMaximisingPlayer));
			      puzzleEditorViewModel.board.chess.undo();
			      beta = Math.min(beta, bestMove);
			      if (beta <= alpha) {
			      	return bestMove;
			      }
	      	}
            //.log("01  ", bestMove);
		      return bestMove;
      	}
      };

      var evaluateBoard = function (board) {
	      var totalEvaluation = 0;
	      for (var i = 0; i < 8; i++) {
	      	for (var j = 0; j < 8; j++) {
		      	totalEvaluation = totalEvaluation + getPieceValue(board[i][j], i ,j);
		      }
      	}
	      return totalEvaluation;
      };

      var reverseArray = function(array) {
	      return array.slice().reverse();
      };

      var pawnEvalWhite =	[
		   [0.0,  0.0,  0.0,  0.0,  0.0,  0.0,  0.0,  0.0],
		   [5.0,  5.0,  5.0,  5.0,  5.0,  5.0,  5.0,  5.0],
		   [1.0,  1.0,  2.0,  3.0,  3.0,  2.0,  1.0,  1.0],
		   [0.5,  0.5,  1.0,  2.5,  2.5,  1.0,  0.5,  0.5],
		   [0.0,  0.0,  0.0,  2.0,  2.0,  0.0,  0.0,  0.0],
		   [0.5, -0.5, -1.0,  0.0,  0.0, -1.0, -0.5,  0.5],
		   [0.5,  1.0, 1.0,  -2.0, -2.0,  1.0,  1.0,  0.5],
		   [0.0,  0.0,  0.0,  0.0,  0.0,  0.0,  0.0,  0.0]
	   ];

      var pawnEvalBlack = reverseArray(pawnEvalWhite);

      var knightEval =	[
		   [-5.0, -4.0, -3.0, -3.0, -3.0, -3.0, -4.0, -5.0],
		   [-4.0, -2.0,  0.0,  0.0,  0.0,  0.0, -2.0, -4.0],
		   [-3.0,  0.0,  1.0,  1.5,  1.5,  1.0,  0.0, -3.0],
		   [-3.0,  0.5,  1.5,  2.0,  2.0,  1.5,  0.5, -3.0],
		   [-3.0,  0.0,  1.5,  2.0,  2.0,  1.5,  0.0, -3.0],
		   [-3.0,  0.5,  1.0,  1.5,  1.5,  1.0,  0.5, -3.0],
		   [-4.0, -2.0,  0.0,  0.5,  0.5,  0.0, -2.0, -4.0],
		   [-5.0, -4.0, -3.0, -3.0, -3.0, -3.0, -4.0, -5.0]
   	];

      var bishopEvalWhite = [
	      [ -2.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -2.0],
	      [ -1.0,  0.0,  0.0,  0.0,  0.0,  0.0,  0.0, -1.0],
	      [ -1.0,  0.0,  0.5,  1.0,  1.0,  0.5,  0.0, -1.0],
	      [ -1.0,  0.5,  0.5,  1.0,  1.0,  0.5,  0.5, -1.0],
	      [ -1.0,  0.0,  1.0,  1.0,  1.0,  1.0,  0.0, -1.0],
	      [ -1.0,  1.0,  1.0,  1.0,  1.0,  1.0,  1.0, -1.0],
	      [ -1.0,  0.5,  0.0,  0.0,  0.0,  0.0,  0.5, -1.0],
	      [ -2.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -2.0]
      ];

      var bishopEvalBlack = reverseArray(bishopEvalWhite);

      var rookEvalWhite = [
	      [  0.0,  0.0,  0.0,  0.0,  0.0,  0.0,  0.0,  0.0],
	      [  0.5,  1.0,  1.0,  1.0,  1.0,  1.0,  1.0,  0.5],
	      [ -0.5,  0.0,  0.0,  0.0,  0.0,  0.0,  0.0, -0.5],
      	[ -0.5,  0.0,  0.0,  0.0,  0.0,  0.0,  0.0, -0.5],
      	[ -0.5,  0.0,  0.0,  0.0,  0.0,  0.0,  0.0, -0.5],
      	[ -0.5,  0.0,  0.0,  0.0,  0.0,  0.0,  0.0, -0.5],
      	[ -0.5,  0.0,  0.0,  0.0,  0.0,  0.0,  0.0, -0.5],
      	[  0.0,   0.0, 0.0,  0.5,  0.5,  0.0,  0.0,  0.0]
      ];

      var rookEvalBlack = reverseArray(rookEvalWhite);

      var evalQueen = [
	      [ -2.0, -1.0, -1.0, -0.5, -0.5, -1.0, -1.0, -2.0],
	      [ -1.0,  0.0,  0.0,  0.0,  0.0,  0.0,  0.0, -1.0],
	      [ -1.0,  0.0,  0.5,  0.5,  0.5,  0.5,  0.0, -1.0],
	      [ -0.5,  0.0,  0.5,  0.5,  0.5,  0.5,  0.0, -0.5],
	      [  0.0,  0.0,  0.5,  0.5,  0.5,  0.5,  0.0, -0.5],
	      [ -1.0,  0.5,  0.5,  0.5,  0.5,  0.5,  0.0, -1.0],
	      [ -1.0,  0.0,  0.5,  0.0,  0.0,  0.0,  0.0, -1.0],
	      [ -2.0, -1.0, -1.0, -0.5, -0.5, -1.0, -1.0, -2.0]
      ];

      var kingEvalWhite = [
      	[ -3.0, -4.0, -4.0, -5.0, -5.0, -4.0, -4.0, -3.0],
      	[ -3.0, -4.0, -4.0, -5.0, -5.0, -4.0, -4.0, -3.0],
      	[ -3.0, -4.0, -4.0, -5.0, -5.0, -4.0, -4.0, -3.0],
      	[ -3.0, -4.0, -4.0, -5.0, -5.0, -4.0, -4.0, -3.0],
      	[ -2.0, -3.0, -3.0, -4.0, -4.0, -3.0, -3.0, -2.0],
      	[ -1.0, -2.0, -2.0, -2.0, -2.0, -2.0, -2.0, -1.0],
      	[  2.0,  2.0,  0.0,  0.0,  0.0,  0.0,  2.0,  2.0 ],
      	[  2.0,  3.0,  1.0,  0.0,  0.0,  1.0,  3.0,  2.0 ]
      ];

      var kingEvalBlack = reverseArray(kingEvalWhite);

      var getPieceValue = function (piece, x, y) {
      	if (piece === null) {
      		return 0;
      	}
      	var getAbsoluteValue = function (piece, isWhite, x ,y) {
      		if (piece.type === 'p') {
      			return 10 + ( isWhite ? pawnEvalWhite[y][x] : pawnEvalBlack[y][x] );
      		} else if (piece.type === 'r') {
      			return 50 + ( isWhite ? rookEvalWhite[y][x] : rookEvalBlack[y][x] );
	            	} else if (piece.type === 'n') {
	      		return 30 + knightEval[y][x];
	      	} else if (piece.type === 'b') {
	      		return 30 + ( isWhite ? bishopEvalWhite[y][x] : bishopEvalBlack[y][x] );
	      	} else if (piece.type === 'q') {
	      		return 90 + evalQueen[y][x];
	      	} else if (piece.type === 'k') {
	      		return 900 + ( isWhite ? kingEvalWhite[y][x] : kingEvalBlack[y][x] );
	      	}
	      	throw "Unknown piece type: " + piece.type;
	      };

	      var absoluteValue = getAbsoluteValue(piece, piece.color === 'w', x ,y);
	      return piece.color === 'w' ? absoluteValue : -absoluteValue;
      };


      var makeBestMove_0 = function (level_0) {
	      //if(gameOvered)return;
	      var bestMove = getBestMove_0(level_0);
         return bestMove;
	      //var moveR = game.move(bestMove);

	      //var moveR = game.move(possibleMoves[randomIdx])
			
	   };


      var getBestMove_0 = function (depth) {
	      //if(gameOvered)return;
	      if (puzzleEditorViewModel.board.chess.game_over()) {
		      //gameOvered=true;
            $("#endGameModal").modal('show');
		      //alert("endGame");
            return;
      	}
	      var bestMove = minimaxRoot(depth, !puzzleEditorViewModel.board.flip());
	      return bestMove;
      };

      //====================end ii===================================

      if (window.level == 1 && puzzleEditorViewModel.board.chess.history().length > 1 && !((puzzleEditorViewModel.board.flip() && puzzleEditorViewModel.board.chess.history().length % 10 === 0)||(!puzzleEditorViewModel.board.flip() && (puzzleEditorViewModel.board.chess.history().length-1) % 10 === 0))){
         var b_moves = puzzleEditorViewModel.board.chess.moves();
         var b_num = Math.floor(Math.random() * b_moves.length);
         var moveObject1 = puzzleEditorViewModel.board.makeMove(b_moves[b_num], true);
         if (moveObject1) {
            if (puzzleEditorViewModel.board.chess.game_over()) {
               $("#endGameModal").modal('show');
               //window.setTimeout(alert('Game over'), 250); 
               //alert('Game over');
            }
         } else {
            console.error('Invalid move:', bestMove);
         }
      }else{

         if (puzzleEditorViewModel.board.chess.history().length == 1){
            const w1 = ["e4", "d4", "Nf3", "c4", "g3", "b3", "f4", "Nc3", "b4", "a3", "e3", "d3", "g4", "c3", "h4", "h3", "a4", "Nh3", "f3", "Na3"];
            const b1 = [["c5", "e5", "e6", "c6", "d6", "g6", "d5", "Nf6", "Nc6", "b6", "a6", "g5", "a5", "h6", "f6", "Na6", "b5", "f5", "h5", "Nh6"],
                        ["Nf6", "d5", "e6", "d6", "g6", "f5", "c5", "Nc6", "c6", "b6", "b5", "e5", "a6", "h6", "h5", "Na6", "f6", "a5", "g5", "Nh6"],
                        ["Nf6", "d5", "c5", "g6", "f5", "d6", "e6", "Nc6", "b6", "b5", "c6", "a6", "h6", "g5", "e5", "f6", "h5", "a5", "Na6", "Nh6"],
                        ["Nf6", "e5", "e6", "c5", "g6", "c6", "f5", "b6", "d6", "Nc6", "d5", "a6", "g5", "b5", "Na6", "a5", "h5", "f6", "h6", "Nh6"],
                        ["d5", "Nf6", "g6", "e5", "c5", "f5", "e6", "d6", "h5", "c6", "Nc6", "b6", "a6", "g5", "b5", "f6"],
                        ["e5", "d5", "Nf6", "c5", "b6", "g6", "d6", "a5", "f5", "e6", "Nc6", "b5", "c6", "a6", "Nh6", "f6", "g5", "h5"],
                        ["d5", "Nf6", "c5", "g6", "e5", "d6", "b6", "f5", "e6", "Nh6", "Nc6", "b5", "c6", "g5", "a6", "h5"],
                        ["d5", "c5", "Nf6", "e5", "g6", "e6", "d6", "Nc6", "c6", "b6", "f5", "a6", "g5", "Na6", "h6"],
                        ["e5", "d5", "Nf6", "c6", "e6", "a5", "d6", "f5", "g6", "b6", "c5"],
                        ["d5", "e5", "g6", "Ngf6", "a6", "c5", "a5", "f5", "h6", "e6", "d6", "b6", "c6", "b5", "g5", "h5", "f6", "Nc6"],
                        ["d5", "Nf6", "g6", "e5", "e5", "c5", "f5", "d6", "e6", "b6"],
                        ["d5", "e5", "g6", "c5", "Nf6", "d6", "e6", "b6", "f5", "c6", "b5", "Nc6", "a6"],
                        ["d5", "e5", "d6", "c5", "h5", "g6", "c6", "Nc6", "g5", "a5", "e6", "Nf6"],
                        ["Nf6", "d5", "e5", "c5", "g6", "f5", "b6", "d6", "e6", "c6", "Nc6", "a6"],
                        ["d5", "e5", "Nf6", "a5", "c5", "d6", "h5", "b6", "e6", "a6", "h6", "f5", "c6", "Nc6", "g6"],
                        ["d5", "e5", "c5", "b6", "a6", "g6", "Nf6", "h6", "d6", "a5", "Nc6", "c6", "b5", "h5"],
                        ["e5", "d5", "Nf6", "a5", "c5", "g6", "e6", "h5", "a6", "d6"],
                        ["d5", "e5", "Nf6", "Nh6", "g6", "Na6", "Nc6", "e6", "c5", "b6", "b5"],
                        ["e5", "d5", "Nf6", "g6", "f6", "d6", "b6"],
                        ["d5", "e5", "g6", "Nf6", "e6"]]
            var n_move1 = w1.indexOf(puzzleEditorViewModel.board.chess.history()[0]);
            var l_moves1 = window.level < 4 ? b1[n_move1].length : b1[n_move1].length / 2;

            var b_num1 = Math.floor(Math.random() * l_moves1);
            var moveObject2 = puzzleEditorViewModel.board.makeMove(b1[n_move1][b_num1], true);
            if (moveObject2) {
               if (puzzleEditorViewModel.board.chess.game_over()) {
                  $("#endGameModal").modal('show');
                  //window.setTimeout(alert('Game over'), 250); 
                  //alert('Game over');
               }
            } else {
               console.error('Invalid move:', bestMove);
            }

         }
         else{
            const fen = puzzleEditorViewModel.board.chess.fen();
            //const b_level = window.level == 1 ? 1 : window.level - 1;
            
            
            //const b_level = window.level == 1 ? 2 : window.level;
            if (window.level > 5 ||(window.level == 5 && !((puzzleEditorViewModel.board.flip() && puzzleEditorViewModel.board.chess.history().length % 6 === 0)||(!puzzleEditorViewModel.board.flip() && (puzzleEditorViewModel.board.chess.history().length-1) % 6 === 0)))){
               const b_level = (window.level == 1 || window.level == 5) ? 2 : window.level-4;
               $.ajax({
                  url: "/puzzles/best_move",
                  type: 'GET', 
                  beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
                  data: ({fen: fen, depth: b_level, host: window.location.hostname}), 
                  dataType: "json",
                  success: function(data) {

                     const bestMove = data.move;
                     if (bestMove) {
                        var moveObject = puzzleEditorViewModel.board.makeMove({from: bestMove.substring(0, 2), to: bestMove.substring(2, 4), promotion: bestMove.length > 4 ? bestMove[4] : 'q' }, true);
                        if (moveObject) {
                           if (puzzleEditorViewModel.board.chess.game_over()) {
                              $("#endGameModal").modal('show');
                              //window.setTimeout(alert('Game over'), 250); 
                              //alert('Game over');
                           }
                        } else {
                           console.error('Invalid move:', bestMove);
                        }
                     }
                  }                  
               });
            
            }else{
               var level_0; 
               if (window.level == 2 ||(window.level == 3 && !((puzzleEditorViewModel.board.flip() && puzzleEditorViewModel.board.chess.history().length % 6 === 0)||(!puzzleEditorViewModel.board.flip() && (puzzleEditorViewModel.board.chess.history().length-1) % 6 === 0)))){
                  level_0 = 1;
               } else {
                  level_0 = 2;
               }
               const bestMove1 = makeBestMove_0(level_0);


               var moveObject2 = puzzleEditorViewModel.board.makeMove(bestMove1, true);
         if (moveObject2) {
            if (puzzleEditorViewModel.board.chess.game_over()) {
               $("#endGameModal").modal('show');
               //window.setTimeout(alert('Game over'), 250); 
               //alert('Game over');
            }
         } else {
            console.error('Invalid move:', bestMove1);
         }
                  //console.log('Best move:', JSON.stringify(data));
                  //console.log('Best move:', bestMove);
                  //if (bestMove) {
                     //var moveObject = puzzleEditorViewModel.board.makeMove({from: bestMove.substring(0, 2), to: bestMove.substring(2, 4), promotion: bestMove.length > 4 ? bestMove[4] : 'q' }, true);
                     //if (moveObject) {
                        //if (puzzleEditorViewModel.board.chess.game_over()) {
                           //window.setTimeout(alert('Game over'), 250); 
                           //////////alert('Game over');
                        //}
                     //} else {
                        //console.error('Invalid move:', bestMove);
                     //}
                  //}

            } 

             /*
            const bestMove = await getBestMove(fen, b_level);// * 3); //Adjust depth based on level
            if (bestMove) {
               var moveObject = puzzleEditorViewModel.board.makeMove({from: bestMove.substring(0, 2), to: bestMove.substring(2, 4), promotion: bestMove.length > 4 ? bestMove[4] : 'q' }, true);
               if (moveObject) {
                  if (puzzleEditorViewModel.board.chess.game_over()) {
                     window.setTimeout(alert('Game over'), 250); 
                     //alert('Game over');
                  }
               } else {
                  console.error('Invalid move:', bestMove);
               }
            }
            */

         }
      }
      $('#board').addClass('my'); 
      $('#board').removeClass('bot');
   });
   window.makeComputerMove = makeComputerMove;
}).call(this);


(function() {
   
  var wpuzzle = (function(puzzle, sign, ipuz, it, help) {
   $('#taskModal2 .description').show();
   $('#taskModal2 .description-1').hide();
   $('#cloud-001 .desc').show();
   $('#board').removeClass('bot'); 
   $('#board').removeClass('my'); 
    var c_t_1,c_t_2;
    var it = it;
    var pn = (puzzle != window.puzzle.id && !help);
    var pn1 = window.puzzle.description;
    $(".start-btn").hide();
    if ($(window).width() > 991) {
      if (st002 && st002.classList.contains("active")) {
        st002.classList.remove("active");
        }  
      if (st002 && st003.classList.contains("active")) {
        st003.classList.remove("active");
        }  
      }
    puzzleEditorViewModel.timeouts = [];
    puzzleEditorViewModel.board.onreset();
    puzzleEditorViewModel.board.pturn(false); 
    puzzleEditorViewModel.board.pmturn(false); 
    puzzleEditorViewModel.board.pcturn(false); 
    puzzleEditorViewModel.board.updateBoard();
    puzzleEditorViewModel.board.palings.removeAll();
    if (resourceUserB == "puzzle" || resourceUserB == "chapter") {
        puzzleEditorViewModel.displayPgn(false);
        puzzleEditorViewModel.displayMessage(true);
     }

    if (sign || ipuz < 2) {
      puzzleEditorViewModel.board.disabled(false);            
      $.ajax({
        url:"/puzzles/solver?puzzle="+puzzle,
        type:'GET',
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
        async:false,
        dataType:'json',
        success:function(data){
                window.puzzle = data;
                window.puzzle.solution = '';
                puzzleEditorViewModel.load(window.puzzle); 
                return window.puzzle;                    
                }});
                if (resourceUserB == "puzzle" || resourceUserB == "chapter") {
                   if (window.puzzle.solved || window.puzzle.err || puzzleEditorViewModel.board.pgn().body.moves.length <3 || 
                      (window.puzzle.hint + window.puzzle.mistake)>= Math.round((puzzleEditorViewModel.board.pgn().body.moves.length-1)/5) || 
                      window.puzzle.userRating > 2300 || (window.puzzle.hint + window.puzzle.mistake) > 3) {
                        puzzleEditorViewModel.hintButton(true);
                   }else {
                     puzzleEditorViewModel.hintButton(false);
                   } 
                   } 
        $('.board-inner .pieces').removeClass('goblin-1'); 
        $('.board-inner .pieces').removeClass('goblin-2');
        $('.board-inner .pieces').removeClass('goblin-3');  
        var goblinNum = Math.floor(Math.random() * 3 ) + 1;  
        if (goblinNum == 1) {
        	 $('.board-inner .pieces').addClass('goblin-1'); 
        }else if (goblinNum == 2) {
        	 $('.board-inner .pieces').addClass('goblin-2'); 
        }else {
        	 $('.board-inner .pieces').addClass('goblin-3');
        }  
        $(".nav-close.active p").click();                 
    }else {
      location.href="users/sign_in";
      }
 
    $('.tasks-content ul li').removeClass('active');
    $('.nav-theme ul li').removeClass('active');    
    $("#puzzle-"+puzzle).addClass("active");   
    $("#theme-"+it).addClass("active");   
$('.task-help').html(window.puzzle.description);
//$('.task-help').html(help);
    puzzleEditorViewModel.board.movelast.removeAll();
        if (resourceUserB == "puzzle" || resourceUserB == "chapter") {
delete window.keyboardController.bind();
          }
//     alert(help + '----' + pn1 + '----' + window.puzzle.description);
     if ((pn && window.puzzle.number == 1)||(pn1 != window.puzzle.description)) {
     	 $("#taskModal2").modal('show');
     }        
  }); 
 window.wpuzzle = wpuzzle;
}).call(this);
(function() {
  var wsolve = (function(puzzle){ 
  if (resourceUserB == "puzzle" || resourceUserB == "chapter") {
        puzzleEditorViewModel.displayPgn(true);
        puzzleEditorViewModel.displayMessage(false);
       window.keyboardController.bind("PgnNavHandler", puzzleEditorViewModel.board);
  }  
    if (!puzzle.solved && !puzzle.err) {
         $.ajax({
          url: "/puzzles/mistake",
          type: 'POST', 
          beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
          data: ({id: window.puzzle.id}), 
          dataType: "json",
          success: function() {
          }                  
        });
      }
    puzzleEditorViewModel.board.disabled(true);        

    cubeAnimateTwo();
 
    $("#taskModal1").modal('show');
if (sound) {document.getElementById("m010").play(); }         
    setTimeout(function() {
     wpuzzle(window.puzzle.id, sign1, window.puzzle.ipuz);
     }, 1000);
      
   });
   window.wsolve = wsolve;
   }).call(this);

(function() {
   var solved = (function(puzzle){ 
      var ls = [];  
      var nRating, koefRating, dR, cR, league;
      league = puzzle.league;
      var lc = ['#ffffff','#fc8a3a','#f9ede3','#f8e83d','#2554b2','#db3d4d','#4ea814','#8a509f','#ffffff','#4cf8f2','#deeffe','#ffffff']
      if (window.puzzle.locale == 'ru') {
         ls = ["", "Бронзовая", "Серебряная", "Золотая", "Сапфировая", "Рубиновая", "Изумрудная", "Аметистовая", "Жемчужная", "Бирюзовая", "Бриллиантовая"];
      }else if (window.puzzle.locale == 'uk') {
         ls = ["", "Бронзова", "Срібна", "Золота", "Сапфірова", "Рубінова", "Смарагдова", "Аметистова", "Перлинна", "Бірюзова", "Діамантова"];
      }else {
         ls = ["", "Bronze", "Silver", "Gold", "Sapphire", "Ruby", "Emerald", "Amethyst", "Pearl", "Turquoise", "Diamond"];
      }
      if (resourceUserB == "puzzle" || resourceUserB == "chapter") {    
      window.keyboardController.bind("PgnNavHandler", puzzleEditorViewModel.board);
      }

      if (!puzzle.solved && !puzzle.err) {
        /* console.log(JSON.stringify(puzzle));*/
         if (puzzle.mistake === 0) {
            dR = 4;
         }else if (puzzle.mistake === 1) {
            dR = 2;
         }else {
            dR = 1;
         };
         nRating = puzzle.userRating + dR;
         $('.achievement .number').html(nRating);
         if (puzzle.league === 0 && nRating > 1) {
            $(".player-liga, .player-next").removeClass("invisible");
         }
         if (puzzle.league === 9 && nRating > 2049) {
            $(".player-next").addClass("invisible");
            puzzle.league = 10;
            $('.player-liga span').html(ls[10]);
            $("#grattersModal").modal('show');
         }
         if ((puzzle.league === 0 && nRating > 1) || 
            (puzzle.league < 2 && nRating > 149) || 
            (puzzle.league < 3 && nRating > 349) || 
            (puzzle.league < 4 && nRating > 549) || 
            (puzzle.league < 5 && nRating > 799) || 
            (puzzle.league < 6 && nRating > 1049) || 
            (puzzle.league < 7 && nRating > 1299) || 
            (puzzle.league < 8 && nRating > 1549) || 
            (puzzle.league < 9 && nRating > 1799)) {
               puzzle.league += 1;
               $('.player-liga span').css("color", lc[puzzle.league]);
               $('.player-liga span').html(ls[puzzle.league]);
               $('.player-next span').css("color", lc[puzzle.league + 1]);
               $('.player-next span').html(ls[puzzle.league + 1]);
               $("#grattersModal").modal('show');
if (sound) {document.getElementById("m011").play(); } 
          }
 
      $.ajax({
         url: "/puzzles/solved",
         type: 'POST', 
         beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
         data: ({id: puzzle.id, n_rating: nRating, dr: dR, league: puzzle.league}), 
         dataType: "json",
         success: function() {
         }                  
      });            
puzzleEditorViewModel.board.disabled(true);        
      window.puzzle.solved = true;
      window.puzzle.err=false;
$('.puzzl'+puzzle.id).addClass("task_done");
      $('#sol').html(+dR);

      };

cubeAnimateOne();

$("#taskModal").modal('show');

/*console.log(window.puzzle.next_p + ' ------ ' + sign1)*/
setTimeout(function() {
   $('#taskModal .close-btn').click();
   wpuzzle(window.puzzle.next_p, sign1, window.puzzle.ipuz, window.puzzle.it);
   }, 1500);      
      
          if (resourceUserB == "puzzle" || resourceUserB == "chapter") {
            puzzleEditorViewModel.displayPgn(true);
         puzzleEditorViewModel.displayMessage(false);
       }  
        
      });
   window.solved = solved;
   }).call(this);

 (function() {
  var mistake = (function(){   
         $.ajax({
          url: "/puzzles/mistake",
          type: 'POST', 
          beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
          data: ({id: window.puzzle.id}), 
          dataType: "json",
          success: function() {
             }                  
      }); 
  
    $("#taskModal1").modal('show');
if (sound) {document.getElementById("m010").play(); }     
    setTimeout(function() {
     $('#taskModal1 .close-btn').click();
     }, 1500);  
      
      });
   window.mistake = mistake;
   }).call(this);
